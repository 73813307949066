@import "/fonts/hermetica.css";
@import "/fonts/astro.css";
@import '/fonts/roboto.css';
@import '/fonts/inter.css';

a {
  text-decoration: none;
}

.m-0 {
  margin: 0px;
}

.m-8 {
  margin: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mx-24 {
  margin: 0 24px !important;
}

.mx-auto {
  margin: 0px auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-6 {
  margin-top: 10px !important;
  margin-right: 10px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-16 {
  padding: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.h-100 {
  height: 100%;
}

.overflow-x {
  overflow-x: hidden;
}

.w-100 {
  width: 100% !important;
}

.flex {
  display: flex !important;
}

.flex-between {
  justify-content: space-between !important;
}

.flex-center {
  justify-content: center !important;
}

.items-center {
  align-items: center;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-muted {
  color: rgba($color: #000000, $alpha: 0.37);
}
.text-muted-dark {
  color: #D3D3D3;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.login-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form {
  max-width: 400px;
}

.global-form {
  max-width: 800px;
}

.draw-form {
  max-width: 100%;
}

[class^="hermetica-"]::before,
[class*=" hermetica-"]::before {
  font-size: 24px !important;
}

.fontSize24 {
  font-size: 24px !important;
}

.transitEvent {
  // float: left;
  padding: 10px 18px;
  overflow: hidden;
  position: relative;
  // width: 240px;
}

// .event-date-time {

// }

.max-w-450 {
  max-width: 450px;
}

.artwork {
  float: right;
  height: 210px;
  padding: 36px 74px;
}

.artwork img {
  border-radius: 50%;
}

.transitCard {
  border: 1px solid #00000026;
  height: 100%;
  border-radius: 22px;
  box-shadow: #00000026 2px 2px 5px;
  &.active {
    border-width: 3px;
    border-color: #000000;
  }
}

.transitCard-dark {
  border: 1px solid #00000026;
  height: 100%;
  border-radius: 22px;
  box-shadow: #00000026 2px 2px 5px;
  background: #000000;
  &.active {
    border-width: 3px;
    border-color: #000000;
  }
}

.h-100v {
  height: 100vh;
}

.h-75v {
  height: 75vh;
}

.tabContainer {
  height: calc(100vh - 54px);
}

.event-scroll-container {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.event-scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.deg {
  vertical-align: top;
}

.edit-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.zoom-btn-container {
  display: none;
  width: 100%;
  justify-content: end;
}
.chart-container {
  position: relative;
  min-width: 60%;
  aspect-ratio: 1;
  min-height: 200px;
  margin-top: 20px;
  // pointer-events: none;
  max-height: 80vh;
  margin-left: auto;
  margin-right: auto;
}
.chart-wrapper {
  width: 100%;
  height: 100%;
}
.increment-led-label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  color: red;
  font-weight: 700;
}

.b-1 {
  border: 1px solid #000000 !important;
}
.b-b-1 {
  border-bottom: 1px solid #000000;
}
.p-1 {
  padding: 4px;
}
.p-r-8 {
  padding-right: 8px;
}

.h-64 {
  min-height: 64px;
  max-height: 64px;
  height: 64px;
}

.h-54 {
  min-height: 54px;
  max-height: 54px;
  height: 54px;
}

.p-t-10vh {
  margin-top: -10vh !important;
}

.input-datetime {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

// count down
.count-down-label {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  display: flex;
  align-items: center;

  color: #569a00;
}

.count-down-label-dark {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  display: flex;
  align-items: center;

  color: #693f9f;
}

.chartlist-title {
  span {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    /* or 167% */
    color: #000000;
    padding-bottom: 16px;
  }

  padding: 8px 16px;
  margin-bottom: 4px;
}

.chartlist-title-dark {
  span {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    /* or 167% */
    color: #fff;
    padding-bottom: 16px;
  }

  padding: 8px 16px;
  margin-bottom: 4px;
}

.me-title {
  span {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    /* or 167% */
    color: #000000;
    padding-top: 2px;
  }

  padding: 8px 16px 0 16px;
}
.me-title-dark {
  span {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    /* or 167% */
    color: #fff;
    padding-top: 2px;
  }
  padding: 8px 16px 0 16px;
}

.signup-savechart-link {
  // font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  padding: 12px 16px;
  cursor: pointer;
}

.signup-birth-field {
  // input[type="datetime-local"]:before {
  //   content: attr(placeholder) !important;
  //   color: #aaa;
  //   margin-right: 0.5em;
  // }
  // input[type="datetime-local"]:focus:before,
  // input[type="datetime-local"]:valid:before {
  //   content: "";
  // }
}

.btn-enter-birth {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
}
.search-btn {
  position: absolute !important;
  top: 45%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, 0);
  padding: 0;
}
.gif-caption {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  color: rgba(0, 0, 0, 0.87);
}
.pro-dialog-price-label-wrapper {
  text-align: center !important;
}
.pro-dialog-price-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 50.7202px !important;
  line-height: 60px !important;
  /* identical to box height, or 118% */

  text-align: center;

  color: rgba(0, 0, 0, 0.87) !important;
}

.pro-dialog-description-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 23.8683px;
  /* identical to box height, or 175% */

  text-align: center;

  color: rgba(0, 0, 0, 0.68);
}

.planets-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.event-chart-wrapper {
  width: 120px;
  height: 120px;
  min-width: 120px;
  position: relative;
}
.event-chart-image {
  margin-top: -40px;
  margin-right: -20px;
}
.artwork-wrapper {
  position: absolute;
  bottom: -10px;
  right: -20px;
  z-index: 2;
  width: 127px;
  height: 127px;
}

.event-dialog-chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-dialog-chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-dialog-chart-image {
}

.event-dialog-view-chart-button {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 16px !important;
  transition: "all 2s",
}

.event-dialog-description-wrapper {
  margin-top: 8px;
  overflow: auto;
}

.event-dialog-description-title {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 12px !important;
}

.event-dialog-description-one {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.event-dialog-description-two {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 15.48px;
  line-height: 18px;
}

.event-dialog-recommend-label {
  font-family: "Helvetica";
  font-style: italic;
  font-weight: 300;
  font-size: 15.48px;
  line-height: 18px;
  margin-top: 40px;
}


// Pro user modal
.pro-modal-title-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 40px 35px;
}

.pro-modal-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: "#6202EE";
}

.pro-dialog-subscribe-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 65px;
  margin-bottom: 116px;
}
// event modal for desktop 
.desktop-event-modal-dialog-wrapper {
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  background: white;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.desktop-event-modal-dialog-wrapper-dark {
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  background: black;
}


@media (max-width: 600px) {
  .draw-chart-form {
    display: flex;
    // align-items: center;
  }

  .draw-chart-input-group {
    max-width: 35%;
    margin-left: 8px;
  }

  .zoom-btn-container {
    display: flex;
    // margin-left: 50%;
    // margin-top: 30%;
  }

  .hide-zoom-container {
    display: none;
  }

  .chart-container {
    margin-top: -15px;
    min-width: 65%;
  }

  .event-chart-image {
    margin-top: -20px;
    margin-right: 0;
  }
}

@media screen and (max-width: 450px) {
  .chart-container {
    // width: 65%;
    min-width: 50%;
    margin-top: 0px;
    // display: flex;
  }

  .zoom-btn-container {
    margin-left: 0;
    // margin-top: 40%;
  }

  // .increment-led-label {

  //   top: 40%;
  //   transform: translate(-40%, 0);
  //   color: red;
  //   font-weight: 700;
  // }
}
