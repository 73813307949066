svg {
  font-size: 7px;
  align-items: center;
  display: flex;
  position: relative !important;
  height: 100%;
  justify-content: center;
}

@media only screen and (max-width: 450px) {
  svg {
    position: relative !important;
    /* margin-top: 16px !important; */
  }
  .MuiCircularProgress-svg {
    margin-top: 0 !important;
  }
  .MuiSvgIcon-root {
    margin-top: 0 !important;
  }
}

.zodiac {
  fill: #ffffff;
  stroke: #000000;
  stroke-width: 1px;
}

.zodiac text {
  fill: #000000;
  stroke: #00000000;
  stroke-width: 0.3px;
}

.houses {
  fill: #000000;
  stroke: #ffffff;
  stroke-width: 1px;
}

.houses text {
  stroke: none;
  fill: #ffffff;
  font-size: 5px
}

.aspects {
  stroke-width: 0.2;
  stroke: #0000003b;
}

.aspects .sextile {
  stroke: green;
}

.aspects .square {
  stroke: red;
}

.aspects .trine {
  stroke: blue;
}

.aspects .opposition {
  stroke: red;
}

.planets {
  fill: white;
  stroke: #000000;
  stroke-width: 0.2px;
}

.planets g :nth-child(3) {
  fill: #000000;
  font-size: 8px;
  stroke: black;
}

.planets g :nth-child(4) {
  fill: #8c8c8c66;
  font-size: 4px;
  stroke: none;
}

.planets path {
  stroke: #000000;
}

.planets .ascendant {
  display: none;
}

.planets .midheaven {
  display: none;
}

.planets .descendant {
  display: none;
}

.planets .imumcoeli {
  display: none;
}

.aspects .ascendant {
  display: none;
}

.aspects .midheaven {
  display: none;
}

.aspects .descendant {
  display: none;
}

.aspects .imumcoeli {
  display: none;
}